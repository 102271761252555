import React from 'react'
import AcademyLayout from 'container/AcademyLayout'
import { graphql} from 'gatsby'
import FrontArticle from 'components/FrontArticle'
import ArticleCard from 'components/ArticleCard'
import Products from 'components/Products'

export default function Academy({data}) {

  const {type} = data.getType.frontmatter;
  const {nodes} = data.allArticles;
  const header = require('data/header.json')

  return <AcademyLayout category={''}>
    <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 md:justify-start'>
        <div>
            {nodes.map((node, i) => {
              console.log(node)
                if(node.frontmatter.featured && node.frontmatter.link==='index' && node.frontmatter.type===type){ 
                  return <FrontArticle frontmatter={node.frontmatter}/>
                }
            })}
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 justify-items-start py-10 pl-2 gap-x-16 gap-y-8 border-b border-grey-500'>
          {nodes.map((node, i)=>{
              if(!node.frontmatter.featured && node.frontmatter.link==='index' && node.frontmatter.type===type){ 
                return <ArticleCard frontmatter={node.frontmatter}/>
              }
          })}
        </div>
        <Products header={header} isAcademy={true}/>  
      </div>
  </AcademyLayout> 
}

export const academyQuery = graphql`
query ($id: String!) {
  getType: markdownRemark(id: { eq: $id }) {
    frontmatter {
      type
    }
  }
  allArticles: allMarkdownRemark{
    nodes {
      frontmatter {
        title
        description
        category
        link
        type
        heroImage
        featured
      }
    }
  }
}
`